<template>
  <div class="container">
    <div class="container_content" v-if="!isEmpty">
      <div class="container_content_item" v-for="(item, i) in list" :key="i"  @click="toActivityDetail(item.id)">
        <img :src="item?.picUrl" alt="">
        <div class="container_content_item_content">
          <div class="container_content_item_content_title">{{ item?.name }}</div>
          <div class="container_content_item_content_center">
            <div class="container_content_item_content_center_status">
              已结束
            </div>
            <div class="container_content_item_content_center_score">可{{ item?.scoreType=='1'?'获得':'消耗' }}{{ item?.score }}积分</div>
          </div>
          <div class="container_content_item_content_bottom">
            <div class="container_content_item_content_bottom_num">已报名：<span style="color:#FF9100;">{{item.alreaySignUpNum}}</span>/{{item.limitNum}}</div>
            <div class="container_content_item_content_bottom_address">{{ item?.addressDetail?.length>10?(item?.addressDetail?.slice(0,10)+'...'):item?.addressDetail }}</div>
          </div>
        </div>
      </div>

    </div>
    <div class="container_empty" v-if="isEmpty">
      <el-empty description="暂无数据"/>
    </div>
  </div>
  <div class="container_page">
    <pagination
        v-show="total > 0"
        :total="total"
        layout="total,  prev, pager, next, jumper"
        v-model:page="query.pageNum"
        v-model:limit="query.pageSize"
        @pagination="onLoad()"
    />
  </div>
</template>

<script setup>
import {useRouter} from "vue-router"
import {ref} from "vue";
import {getSignupList} from "@/api/activity";

const router = useRouter();

const isEmpty = ref(false)
//分页
const query = ref({})
query.value = {pageNum: 1, pageSize: 8}

const total = ref(1);
const list = ref([]);

const onLoad = async () => {
  let response = await getSignupList(query.value, {status:"1",activityStatus:"0"});
  console.log(response.rows)
  if (response.rows.length > 0) {
    for (const e of response.rows) {
      list.value = list.value.concat(e.activity)
    }
    total.value = response.total
  }
  if(query.value.pageNum==1){
    if(response.rows.length==0){
      isEmpty.value=true
    }
  }
}
onLoad()
const toActivityDetail = (id) => {
  router.push({
    path: 'activityDetail',
    query: {
      id,
    },
  })
}
</script>

<style lang="scss" scoped>
.container_page {
  background-color: #F5F5F5;
  height: 60px;
  text-align: center;
  display: inline-block;
}
.container {
  background-color: #F5F5F5;
  padding: 60px 60px 12px 100px;

  &_empty {
    padding: 0 30px 30px 30px;
    height: 930px;
  }

  &_content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 930px;
    position: relative;

    &_item {
      margin-right: 40px;
      margin-bottom: 40px;
      width: 395px;
      height: 410px;
      border-radius: 6px 6px 6px 6px;
      background-color: #FFFFFF;

      img {
        width: 395px;
        height: 200px;
      }

      &_content {

        padding: 20px 20px 38px 20px;
        border-radius: 0 0 6px 6px;
        display: flex;
        flex-direction: column;

        &_title {
          top: 20px;
          line-height: 30px;
          color: rgba(33, 33, 33, 100);
          font-size: 20px;
          text-align: left;
          font-family: HarmonyOS_Sans_SC-medium;
        }

        &_center {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &_status {
            border-radius: 2px;
            background-color: rgba(33, 33, 33, 100);
            text-align: center;
            color: rgba(255, 255, 255, 100);
            font-size: 14px;
            font-family: PingFangSC-medium;
            font-weight: bolder;
            padding: 3px;
          }

          &_score {
            line-height: 26px;
            color: rgba(33, 33, 33, 100);
            font-size: 16px;
            text-align: left;
            font-family: PingFangSC-medium;
          }
        }

        &_bottom {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &_num {
            line-height: 16px;
            color: rgba(97, 97, 97, 100);
            font-size: 16px;
            text-align: left;
            font-family: PingFangSC-regular;
          }

          &_address {
            line-height: 16px;
            color: rgba(189, 189, 189, 100);
            font-size: 16px;
            text-align: left;
            font-family: PingFangSC-regular;
          }
        }
      }
    }

    &_next {
      width: 395px;
      height: 410px;
      border-radius: 6px 6px 6px 6px;
      background-color: #FFFFFF;
      position: absolute;
      left: 1305px;
      top: 464px;

      &_content {
        margin-top: 72px;
        height: 266px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .el-button {
          width: 320px;
          height: 80px;
        }

        &_page {
          line-height: 20px;
          color: rgba(140, 162, 170, 100);
          font-size: 18px;
          text-align: center;
          font-family: HarmonyOS_Sans_SC-regular;
          margin-top: 43px;
          margin-bottom: 43px;
        }
      }
    }
  }

}
</style>
